import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// import {
//   // Modal,
//   Paper,
//   Avatar,
//   Button,
//   Box,
//   Container,
//   Typography
// } from '@material-ui/core';
// import { alpha } from '@material-ui/core/styles';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import LoginErr from '../pages/authentication/LoginErr';
// import CheckIcon from '../icons/Check';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const tx = 'true';
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  // const [openSuccess, setOpenSuccess] = useState(true);
  // const navigate = useNavigate();
  // const { logout } = useAuth();

  // const handleOpenSuccess = () => {
  //   setOpenSuccess(true);
  // };

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }
  if (auth.isAuthenticated && !auth.user.is_approved) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    console.log('Not Approved ');
    return <LoginErr dialog={tx} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
