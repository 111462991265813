import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotFound from 'src/pages/NotFound';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
// import { Dashboard } from '@material-ui/icons';

interface RoleBasedGuardProps {
  children: ReactNode;
}

const RoleBasedGuard: FC<RoleBasedGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }
  // if user isnt a teacher, go to login
  if (!auth.user.is_teacher) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <NotFound />;
  }
  // if (auth.user.name) {
  //   if (location.pathname !== requestedLocation) {
  //     setRequestedLocation(location.pathname);
  //   }

  //   return <Login />;
  // }
  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // call firebase and get user status
  console.log('A1x');
  console.log(auth.user);
  console.log('B1x');

  return <>{children}</>;
};

RoleBasedGuard.propTypes = {
  children: PropTypes.node
};

export default RoleBasedGuard;
