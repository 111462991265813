import type { FC } from 'react';
import { useEffect } from 'react';
// import Modal from '@material-ui/core/Modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  TextField,
  // Paper,
  // Avatar,
  Button,
  Box,
  FormHelperText,
  Alert,
  // Container,
  // Typography
} from '@material-ui/core';
// import { alpha } from '@material-ui/core/styles';
import { string } from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// import CheckIcon from '../../../icons/Check';

interface LoginAccFormProps {
  dialog?: String;
}

const LoginFirebaseAccessAble: FC<LoginAccFormProps> = (props) => {
  // let { dialog } = props;
  const isMountedRef = useIsMountedRef();
  const { signInWithEmailAndPassword } = useAuth() as any;
  // const [openSuccess, setOpenSuccess] = useState(false);
  // const navigate = useNavigate();
  // const { logout } = useAuth();

  // const handleOpenSuccess = () => {
  //   setOpenSuccess(true);
  // };

  // const handleCloseSuccess = async () => {
  //   dialog = '';
  //   setOpenSuccess(false);
  //   navigate('/dashboard/');
  //   await logout();
  // };

  // const Search = () => {
  //   if (dialog === 'true') {
  //     console.log('Not Approvedx');
  //     handleOpenSuccess();
  //     dialog = '';
  //     // logout();
  //   }
  // };

  // Search();

  // console.log(dialog);
  // console.log(dialog);

  useEffect(() => {
    // gtm.push({ event: 'page_view' });
    console.log('effect fn');
  }, []);

  return (
    <div {...props}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            .shape({
              email: Yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup
                .string()
                .max(255)
                .required('Password is required')
            })
        }
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            // console.error('Trying....');
            // console.log(dialog);
            // console.error('Trying....Sam1');
            // handleOpenSuccess();
            await signInWithEmailAndPassword(values.email, values.password);
            // console.error('Trying....Sam2');
            // console.log(dialog);
            // if (dialog === 'true') {
            //   handleOpenSuccess();
            // }
            // console.error('Am in ...');
            if (isMountedRef.current) {
              setStatus({ success: true });
              // setErrors({ submit: err.message });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            {/* <Modal
              open={openSuccess}
              onClose={handleCloseSuccess}
            >
              {successBody}
            </Modal> */}
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              {/* <Button
                color="primary"
                onClick={handleOpenSuccess}
                fullWidth
                size="large"
                // type="submit"
                variant="contained"
              >
                Log Inx
              </Button> */}
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Alert severity="info">
                {/* <div>
                  Contact Admin on
                  {' '}
                  <b>0789090933</b>
                  {' '}
                  for Account
                  {' '}
                  <b>Creation</b>
                </div> */}
                <div>
                  Health Care
                  {' '}
                  <b>for</b>
                  {' '}
                  the
                  {' '}
                  <b>Future</b>
                </div>
              </Alert>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

LoginFirebaseAccessAble.propTypes = {
  // @ts-ignore
  // customer: PropTypes.object.isRequired
  // applicant: PropTypes.object.isRequired,
  dialog: string,
};

export default LoginFirebaseAccessAble;
