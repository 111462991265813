// import React, { useContext, useRef } from 'react';
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/quotes */
// import OneSignal from 'react-onesignal';
import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import './i18n';
import { WebClient } from '@slack/web-api';
import ZoomVideo, { ConnectionState } from '@zoom/videosdk';
import ZoomContext from './zoomcontext/zoom-context';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';
// import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';
// import "./some-library/dist/some-library.js";
// const { WebClient } = require('@slack/web-api');

// const zmClient = ZoomVideo.createClient();
// const streamy = zmClient.getMediaStream();
// setMediaStream(streamy);
// console.log('MULTIPLE LOGxd' + ZoomVideo.createClient().getMediaStream().isSupportMultipleVideos());

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  // SLACK STUFF //
  // An access token (from your Slack app or custom integration - xoxp, xoxb)
  // const token = process.env.SLACK_TOKEN;
  const slacktoken = "xoxb-2890676697463-2929147672544-BvO0PCBtUiHqHr9HGZVCFFz7";
  const web = new WebClient(slacktoken);

  // This argument can be a channel ID, a DM ID, a MPDM ID, or a group ID
  const conversationId = "C02SJASTW3X";

  // const zmClient = ZoomVideo.createClient();
  // console.log('MULTIPLE LOGxd' + zmClient.getMediaStream().isSupportMultipleVideos());

  // const { user } = useAuth();
  useScrollReset();

  // useEffect(() => {
  //   // OneSignal.init({
  //   //   appId: "251a2fb1-ae9f-4cc9-b9bb-18650a3f9bba"
  //   // });
  //   OneSignal.sendTag("zoomhealth", "ctc main").then(() => {
  //     console.log("TAGGED ZoomHealth");
  //   });
  // }, []);

  useEffect(() => {
    // await zmClient.init("en-US", `${window.location.origin}/lib`, 'zoom.us');
    // const init = async () => {
    //   await zmClient.init("en-US", `${window.location.origin}/lib`, 'zoom.us');
    // };
    // init();

    fetch('https://tokengen.4ws.live/express_backend', {
      method: "GET",
      // headers: headersx,
      // body: JSON.stringify(dataxr),
      // body: dataxr,
      // mode: 'no-cors'
    })
      .then((response: { json: () => any; }) => response.json())
      // Then with the data from the response in JSON...
  
      .then((datax: any) => {
        console.log("slack data", datax);
      })
      // Then with the error genereted...
      .catch((error: any) => {
        console.error("Error:", error);
      });
    
    // (async () => {
    //   // See: https://api.slack.com/methods/chat.postMessage
    //   const res = await web.chat.postMessage({ channel: conversationId, text: 'Hello there; Welcome to CTC Main VCC' });
    //   // `res` contains information about the posted message
    //   console.log('Message sent: ', res.ts);
    // })();

    // // Now let's the WebClient to send to the workspace
    // web.chat.postMessage({ channel: conversationId, text: 'Hello VCC Main Staging tests!' })
    //   .then((res) => {
    //     // `res` contains information about the posted message
    //     console.log('PDTN Message sent: ', res.ts);
    //   })
    //   .catch(console.error);

    gtm.initialize(gtmConfig);
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          <GlobalStyles />
          {/* <SettingsDrawer /> */}
          {/* // if initiliazed show logo */}
          {auth.isInitialized ? content : <SplashScreen />}
        </SnackbarProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
