import { useEffect, useState } from 'react';
// import { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { alpha } from '@material-ui/core/styles';
import { string } from 'prop-types';
// import IdleTimer from 'react-idle-timer';
// import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Paper,
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Divider,
  Typography
} from '@material-ui/core';
// import AuthBanner from '../../components/authentication/AuthBanner';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebaseAccessAble,
  LoginCTIMain,
  LoginJWT
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import CheckIcon from '../../icons/Check';

interface LoginFormProps {
  dialog?: String;
}

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg'
};

const Login: FC<LoginFormProps> = (props) => {
  const { dialog } = props;
  const { platform } = useAuth() as any;

  const [openSuccess, setOpenSuccess] = useState(false);

  // setOpenSuccess(true);
  // componentDidMount(){
  //   setOpenSuccess(true);
  // };

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
    // navigate('/dashboard/applicants/');
    // await logout();
  };

  const successBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mb: 2
            }}
          >
            <CheckIcon />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Applicant Blocked
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            A user has successfuly been updated;
          </Typography>
          <Button
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseSuccess}
          >
            Okay, Noted
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    if (dialog === 'trued') {
      console.log(dialog);
      handleOpenSuccess();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | ZoomHealth</title>
      </Helmet>
      <Modal
        open={openSuccess}
        onClose={handleCloseSuccess}
      >
        {successBody}
      </Modal>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        {/* <AuthBanner /> */}
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <div>
                  <Typography
                    // align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    flex="1"
                  >
                    Log in
                  </Typography>
                  <Typography
                    // align="center"
                    color="textSecondary"
                    variant="h6"
                  >
                    Log in to access Zoom Health
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                >
                  <img
                    alt="Auth platform"
                    src={platformIcons[platform]}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginCTIMain dialog={dialog} />}
                {platform === 'JWT' && <LoginCTIMain />}
              </Box>
              <Divider sx={{ my: 3 }} />
              {/* <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link> */}
              {platform === 'Firebase' && (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  sx={{ mt: 1 }}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  Forgot password
                </Link>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

Login.propTypes = {
  // @ts-ignore
  // customer: PropTypes.object.isRequired
  // applicant: PropTypes.object.isRequired,
  dialog: string,
};

export default Login;
