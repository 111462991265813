/* eslint-disable prefer-template */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
import React, { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
// import firebase, { userscollectionRef, logscollectionRef } from '../lib/firebase';
// import type { FirebaseUser } from '../types/user';
// import { createContext, useEffect, useReducer } from 'react';
// import type { FC, ReactNode } from 'react';
// import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import {
  Modal,
  TextField,
  Paper,
  Avatar,
  Button,
  Box,
  FormHelperText,
  Alert,
  InputAdornment,
  IconButton,
  Container,
  Typography
} from '@material-ui/core';
import { sign, verify } from 'jsonwebtoken';
import CircularProgress from '@material-ui/core/CircularProgress';
import { devConfig } from 'src/zoomconfig/dev';
import type { CTIUser } from '../types/user';
// import { verify, JWT_SECRET } from '../utils/jwt';
// import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
import CheckIcon from '../icons/Check';
import X from '../icons/X';
import axios from '../lib/axios';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: CTIUser | null;
}

// interface AuthContextValue extends State {
//   platform: 'JWT';
//   login: (email: string, password: string) => Promise<void>;
//   logout: () => Promise<void>;
//   register: (email: string, name: string, password: string) => Promise<void>;
// }

interface AuthContextValue extends State {
  platform: 'JWT';
  ctilogin: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: CTIUser | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean;
    isInitialized: boolean,
    user: CTIUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: CTIUser;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  ctilogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
    // navigate('/dashboard/courses');
    // await logout();
  };
  const [openProgress, setOpenProgress] = useState(false);

  const handleOpenProgress = () => {
    setOpenProgress(true);
  };

  const handleCloseProgress = () => {
    setOpenProgress(false);
  };

  const [openError, setOpenError] = useState(false);

  // const [openProgress, setOpenProgress] = useState(false);

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  // drs access
  const [openErrorDrsAccess, setOpenErrorDrsAccess] = useState(false);

  const handleOpenErrorDrsAccess = () => {
    setOpenErrorDrsAccess(true);
  };

  const handleCloseErrorDrsAccess = () => {
    setOpenErrorDrsAccess(false);
  };
  //

  // clinic access
  const [openErrorClinicAccess, setOpenErrorClinicAccess] = useState(false);

  // const [openProgress, setOpenProgress] = useState(false);

  const handleOpenErrorClinicAccess = () => {
    setOpenErrorClinicAccess(true);
  };

  const handleCloseErrorClinicAcess = () => {
    setOpenErrorClinicAccess(false);
  };
  //

  const [openErrord1, setOpenErrord1] = useState(false);

  // const [openProgress, setOpenProgress] = useState(false);

  const handleOpenErrord1 = () => {
    setOpenErrord1(true);
  };

  const handleCloseErrord1 = () => {
    setOpenErrord1(false);
  };

  const progressBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {/* <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar> */}
          <CircularProgress />
          <br />
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Accessing Clinic
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Please wait ...
            <br />
            {/* Thanks */}
          </Typography>
          {/* <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseError}
          >
            Okay
          </Button> */}
        </Paper>
      </Container>
    </Box>
  );

  const successBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mb: 2
            }}
          >
            <CheckIcon />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Unit Content Added
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            A unit content has successfuly been added;
          </Typography>
          <Button
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseSuccess}
          >
            Okay, Noted
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  const errorBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Error Detected
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Wrong Email, Username, Phonenumber  or Password ...
          </Typography>
          <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseError}
          >
            Okay
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  const errorBodyDrsAccess = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Access Error Detected
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Only Authorized Personnel can access the portal ...
          </Typography>
          <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseErrorDrsAccess}
          >
            Okay
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  const errorBodyClinicAccess = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Clinic Access Error Detected
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Your not allowed to access this Portal ...
          </Typography>
          <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseErrorClinicAcess}
          >
            Okay
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  const errorBodyd1 = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Error Detected
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Duplicate session ...
          </Typography>
          <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseErrord1}
          >
            Okay
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  useEffect(() => {
    // if (user) {

    // }
    // Revise team
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        console.log('INITIALIZING ... ' + accessToken);

        // if (userx !== 'undefined'){
        // }

        // if (accessToken && verify(accessToken, JWT_SECRET)) {
        if (accessToken && verify(accessToken, 'Mz6wccjuiYkmqrOs9KOn2CAWD0yV7IoW')) {
          setSession(accessToken);
          console.log('INITIALIZED SUCCESS123');

          // const response = await axios.get<{ user: CTIUser }>('/api/identity/me');
          // const { user } = response.data;

          // dispatch({
          //   type: 'INITIALIZE',
          //   payload: {
          //     isAuthenticated: true,
          //     // user
          //   }
          // });
          const userx = window.localStorage.getItem('userx');
        // console.log('INITIALIZING ...x ' + userx);
          if (userx == null) {
            console.log('Not defined');
            // dispatch({ type: 'LOGOUT' });
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                // isInitialized: false,
                user: null
                // user: JSON.parse(userx)
                // user: {
                //   is_approved: false,
                //   is_teacher: true,
                //   is_student: false,
                //   id: 3232,
                //   surname: 'sam',
                //   given_name: 'daes',
                //   cti_id: 'CTI_dsd',
                //   email: 'poew',
                //   token: 'rerere'
                // }
                }
              });
          } else {
            console.log('Defined vvv');
            // const userx: CTIUser;
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                // isInitialized: false,
                user: JSON.parse(userx)
                // user: {
                //   id: userx1.id,
                //   surname: userx.surname,
                //   given_name: userx.given_name,
                //   cti_id: userx.cti_id,
                //   email: userx.email,
                //   phone_number: userx.phone_number,
                //   gender: userx.gender,
                //   photo_url: userx.photo_url,
                //   account_type_id: userx.account_type_id,
                //   account_type: userx.account_type,
                //   token: userx.token,
                //   is_approved: userx.is_approved,
                //   is_teacher: userx.is_teacher,
                //   is_student: userx.is_student,
                //   session_id: userx.session_id,
                //   userId: userx.userId
                // }
              }
              });
              // console.log("USEX" + user);
            // dispatch({
            //   type: 'LOGIN',
            //   payload: {
            //     isAuthenticated: true,
            //     isInitialized: true,
            //     user: JSON.parse(userx)
            //     }
            //   });
            // logout
            // setSession(null);
            // dispatch({ type: 'LOGOUT' });
          }
        } else {
          console.log('INITIALIZED FAILED123');
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        // console.error(err);
        console.log('Check Err: s108');
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const ctilogin = async (email: string, password: string): Promise<void> => {
    // from core //
    handleOpenProgress();
    const dataxr = {
      "login": email,
      "password": password
      // "contents": { "en": regMesso }
    };
    const headersx = {
      "Content-Type": "application/json",
      // "Authorization": "Basic ZTFhNWEzYzUtNTM2NS00NWM3LWI1NzMtN2QwNGE0Njg0YmFi"
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    };
    const urlx = devConfig.mainUrl + "login";
    // const urlx = "https://cors-anywhere.herokuapp.com/" + "https://core.ctiafrica.io/v1/login";
    // const urlx = "/api/v1/rest-auth/login/";
    // https://cors-anywhere.herokuapp.com/
    
    fetch(urlx, {
      method: "POST",
      headers: headersx,
      body: JSON.stringify(dataxr),
      // body: dataxr,
      // mode: 'no-cors'
    })
    .then((response: { json: () => any; }) => response.json())
    // Then with the data from the response in JSON...

    .then((data: any) => {
      // const rex = data['non_field_errors'];
      // if (data)
      console.log("Data:", data);
      // return withSuccess;
      if (data['status_code'] === 100) {
        console.log("Success Code:", data['status_code']);
        console.log("Success Code2:", data['status']);
        console.log("Success Code3:", data['user']);
        // user in user context
        console.log("Success Code3:", data['user']['surname']);
        console.log("Success Code8:", data['user']['id']);
        console.log("Success Code9:", data['user']['account_type_id']);

        // check 1
        // does user have a valid account type ...
        // valid account types are
        // 2, 3, 4, 5, 8, 12, 20, 21
        // new added
        // 23, 26, 27, 28, 29
        // if (data['user']['account_type_id'] === 12 || data['user']['account_type_id'] === 3 || data['user']['account_type_id'] === 4
        //   || data['user']['account_type_id'] === 2 || data['user']['account_type_id'] === 8 || data['user']['account_type_id'] === 5
        //   || data['user']['account_type_id'] === 20 || data['user']['account_type_id'] === 21
        //   || data['user']['account_type_id'] === 23 || data['user']['account_type_id'] === 26 || data['user']['account_type_id'] === 27
        //   || data['user']['account_type_id'] === 28 || data['user']['account_type_id'] === 29

        //   ) {

        if (data['user']['is_vcc_agent'] === 1) {
          // continue
          // check 1 passed ... user is a doctor ...
          // check 2
          // Is the user allowed to access the portal ...
          let user_access_clinics = [];
          // console.log("NOW88");
          // var jsonDatax = JSON.parse(JSON.stringify(jsonDatax_login.user))
          // console.log(jsonDatax_login.user.specialist_vcc_clients.length)

          if (data.user.specialist_vcc_clients.length > 0) {
            for (let i = 0; i < data.user.specialist_vcc_clients.length; i++) {
              let spx = data.user.specialist_vcc_clients[i];
              // create and add to array
              user_access_clinics.push(spx.ctc_client_id);
              console.log(spx.ctc_client_id);
              console.log("NOW99" + user_access_clinics);
            }

          // pdtn
          // dev
          // [1, 2]
          // multi vcc
          // [1, 2]
          // change to clinic ID
          // 3 is for sas, 1 for main ctc hub
          // 6 is for IML
          // multivcc
          if (user_access_clinics.includes(devConfig.clinicId)) {
            const headersxz1 = {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + data['user']['token']
              // 'Access-Control-Allow-Origin': '*',
              // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            };
            // multi vcc
            // 1 ctc comand center
            // 3 is for sas
            // 6 is for IML
            const urlxz1i = devConfig.mainUrl + "session/list?cti_id=" + data['user']['cti_id'] + "&clinic_id=" + String(devConfig.clinicId) + "&host_status=0&login_status=1";
            const urlxz1 = devConfig.mainUrl + "session/list?cti_id=" + data['user']['cti_id'] + "&clinic_id=" + String(devConfig.clinicId) + "&login_status=1";
            
            // detects if there is a session online ... (check many statuses ... 0,1,2)
            // detects if user is logged in ...
            // login status is 1 - user logged in
            // login status is 0 - user not logged in
            // host_status is 0 - first access to portal
            // host_status is 1 - session connected ... session has started
            // host_status is 2 - session not engaged ... connected and can receive calls ...
            // 1 and 2 can receive calls ....
            // disconnection leads to host status 1 to 2
    
            console.log("URLX: " + urlxz1);
            // https://core.ctiafrica.io/v1/session/list?cti_id=CTI_79_BK_0000222&clinic_id=3&host_status=0
            // const urlx = "https://cors-anywhere.herokuapp.com/" + "https://core.ctiafrica.io/v1/login";
            // const urlx = "/api/v1/rest-auth/login/";
            // https://cors-anywhere.herokuapp.com/
            
            fetch(urlxz1, {
              method: "GET",
              headers: headersxz1,
              // body: JSON.stringify(dataxrz1),
              // body: dataxr,
              // mode: 'no-cors'
            })
            .then((response: { json: () => any; }) => response.json())
            // Then with the data from the response in JSON...
      
            .then((datax1: any) => {
              if (datax1['status_code'] === 100) {
                console.log("Success Code8x:", datax1['response']['current_page']);
                console.log("Success Code9x:", datax1['response']['data']);
                console.log("Success Code9x2:", datax1['response']['data'].length);
                if (datax1['response']['data'].length === 0 || datax1['response']['data'].length > 0) {
                  // No session exists for this user ... (This is the first session)
                  // if user has session with host 0 created... terminate existing sessions ...
                  // How can we tell its not a duplicate ... check login_status if its 1.
                  // No user with host 0....
    
                  setSession(data['user']['token']);
                  const userx: CTIUser = {
                    id: data['user']['id'],
                    surname: data['user']['surname'],
                    given_name: data['user']['given_name'],
                    cti_id: data['user']['cti_id'],
                    email: data['user']['email'],
                    phone_number: data['user']['phone_number'],
                    gender: data['user']['gender'],
                    photo_url: data['user']['photo_url'],
                    account_type_id: data['user']['account_type_id'],
                    account_type: data['user']['account_type'],
                    speciality_id: data['user']['speciality_id'],
                    speciality: data['user']['speciality'],
                    token: data['user']['token'],
                    is_approved: true,
                    is_teacher: true,
                    is_student: false,
                    session_id: '',
                    userId: data['user']['id']
                  };
                  localStorage.setItem('userx', JSON.stringify(userx));
    
                  dispatch({
                    type: 'LOGIN',
                    payload: {
                      isAuthenticated: true,
                      isInitialized: true,
                      user: {
                        id: data['user']['id'],
                        surname: data['user']['surname'],
                        given_name: data['user']['given_name'],
                        cti_id: data['user']['cti_id'],
                        email: data['user']['email'],
                        phone_number: data['user']['phone_number'],
                        gender: data['user']['gender'],
                        photo_url: data['user']['photo_url'],
                        account_type_id: data['user']['account_type_id'],
                        account_type: data['user']['account_type'],
                        speciality_id: data['user']['speciality_id'],
                        speciality: data['user']['speciality'],
                        token: data['user']['token'],
                        is_approved: true,
                        is_teacher: true,
                        is_student: false
                      }
                    }
                  });
                  
                  handleCloseProgress();
                  
                  // const dataxrz90 = {
                  //   "login_status": "1"
                  // };
                  // const headersxz90 = {
                  //   "Content-Type": "application/json",
                  //   "Authorization": "Bearer " + data['user']['token']
                  //   // 'Access-Control-Allow-Origin': '*',
                  //   // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                  // };
                  // const urlxz90 = "https://core.ctiafrica.io/v1/session/update";
              
                  // fetch(urlxz90, {
                  //   method: "POST",
                  //   headers: headersxz90,
                  //   body: JSON.stringify(dataxrz90),
                  // })
                  // .then((response: { json: () => any; }) => response.json())
                  // // Then with the data from the response in JSON...
              
                  // .then((datax9: any) => {
                  //   if (datax9['status_code'] === 100) {
                  //     if (datax9['response']['login_status'] === "1") {
                        
                  //     } else {
                  //       console.log("Error happened in destroying a session ...");
                  //       handleOpenError();
                  //     }
                  //   }
                  // })
                  // // Then with the error genereted...
                  // .catch((error: any) => {
                  //   console.error('Error:', error);
                  // });
    
                  // init/create a session for this user ///
                  console.log("Init user session now core ... No duplicate session yet");
                  // console.log("Session ID: 3" + data['user']['cti_id'] + new Date().getTime());
                  // passcode, max 10 characters
                  // const dataxrz2 = {
                  //   "session_id": "3" + data['user']['cti_id'] + new Date().getTime(),
                  //   "passcode": "angelia@09",
                  //   "clinic_id": "3",
                  //   "name": data['user']['surname'] + data['user']['given_name'],
                  //   "cti_id": data['user']['cti_id']
                  //   // "contents": { "en": regMesso }
                  // };
                  // const headersxz2 = {
                  //   "Content-Type": "application/json",
                  //   "Authorization": "Bearer " + data['user']['token']
                  //   // 'Access-Control-Allow-Origin': '*',
                  //   // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                  // };
                  // const urlxz2 = "https://core.ctiafrica.io/v1/session/create";
          
                  // fetch(urlxz2, {
                  //   method: "POST",
                  //   headers: headersxz2,
                  //   body: JSON.stringify(dataxrz2),
                  //   // body: dataxr,
                  //   // mode: 'no-cors'
                  // })
                  // .then((response: { json: () => any; }) => response.json())
                  // // Then with the data from the response in JSON...
            
                  // .then((datax2: any) => {
                  //   if (datax2['status_code'] === 100) {
                  //     if (datax2['response']['host_status'] === "0") {
                  //       // session created successfully ... 
                        
                  //       handleCloseProgress();
                  //       // init a session for this user ///
                  //       // console.log("Session init successfully; " + datax2['response']['isactive']);
                  //       console.log("Session initXXX successfully; " + datax2['response']['session_id']);
                  //       // dispatch here ...
                  //       // set session params here ...
                  //       // localStorage.setItem('accessToken', accessToken);
                  //       localStorage.setItem('session_id', datax2['response']['session_id']);
                  //       localStorage.setItem('passcode', datax2['response']['passcode']);
                  //       localStorage.setItem('host_status', datax2['response']['host_status']);
                  //       localStorage.setItem('expiry_date', datax2['response']['expiry_date']);
                  //       // dispatch here    
                  //     } else {
                  //       console.log("Error happened in initing a session ...");
                  //       handleCloseProgress();
                  //       // send error ...
                  //       handleOpenError();
                  //     }
                  //   }
                  // })
                  // // Then with the error genereted...
                  // .catch((error: any) => {
                  //   console.error('Error:', error);
                  //   // return 'FAILEDx';
                  // });
                } 
                
                // else {
                //   // host_status is not 0, probably other.... means user has a session
                //   // login_status is 1, probable duplicate session.
    
                //   console.log("User has an active and session with 0 / User already login ...");
                //   // display error ....
                //   handleCloseProgress();
    
                //   handleOpenErrord1();
                // }
              }
            })
            // Then with the error genereted...
            .catch((error: any) => {
              console.error('Error:', error);
              // return 'FAILEDx';
            });  
          } else {
            console.log("NOW99- Failed1");
            // check 2 failed
            // user doesnt have access rights to this clinic ...
            handleCloseProgress();
            handleOpenErrorClinicAccess();
          }
          } else {
            console.log("NOW99- Failed2");
            // check 2 failed
            // user doesnt have access rights to this clinic ...
            handleCloseProgress();
            handleOpenErrorClinicAccess();
          }
        } else {
          console.log("NOW99- Failed3");
          // check 1 failed
          // user doesnt have a valid account type ...
          handleCloseProgress();
          handleOpenErrorDrsAccess();
        }

        // handleCloseProgress();
        // const { user } = data['user'];
        // console.log("Success Code9:", data.user.id);
        // setSession(data['user']['token']);
        // const userx: CTIUser = {
        //   id: data['user']['id'],
        //   surname: data['user']['surname'],
        //   given_name: data['user']['given_name'],
        //   cti_id: data['user']['cti_id'],
        //   email: data['user']['email'],
        //   phone_number: data['user']['phone_number'],
        //   gender: data['user']['gender'],
        //   photo_url: data['user']['photo_url'],
        //   account_type_id: data['user']['account_type_id'],
        //   account_type: data['user']['account_type'],
        //   token: data['user']['token'],
        //   is_approved: true,
        //   is_teacher: true,
        //   is_student: false,
        //   session_id: '',
        //   userId: data['user']['id']
        // };
        // localStorage.setItem('userx', JSON.stringify(userx));
        // handleCloseProgress();
        // dispatch({
        //   type: 'LOGIN',
        //   payload: {
        //     user
        //   }
        // });
      } else {
        // error invalid credentials ....
        console.log("Error Code:", data['status_code']);
        // if (isMountedRef.current) {
        //   setStatus({ success: false });
        //   setErrors({ submit: 'Wrong Credentials; Enter correct Email and Password' });
        //   setSubmitting(false);
        // }
        handleCloseProgress();
        handleOpenError();
      }
      // return 'FAILEDx';
    })
    // Then with the error genereted...
    .catch((error: any) => {
      console.error('Error:', error);
      // return 'FAILEDx';
    });
    // const response = await axios.post<{ accessToken: string; user: CTIUser }>('/api/authentication/login', {
    //   email,
    //   password
    // });
    // const { user } = response.data;
  };

  const logout = async (): Promise<void> => {
    console.log('Session ID: ' + window.localStorage.getItem('session_id'));
    console.log('Session Token: ' + window.localStorage.getItem('accessToken'));
    if (window.localStorage.getItem('session_id') == null) {
      console.log('LOGGED OUT');
      window.localStorage.removeItem('userx');
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    } else {
        const dataxrz9 = {
          "session_id": window.localStorage.getItem('session_id'),
          "host_status": "4",
          "login_status": "0"
          // "contents": { "en": regMesso }
        };
        const headersxz9 = {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + window.localStorage.getItem('accessToken')
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        };
        const urlxz9 = devConfig.mainUrl + "session/update";
    
        fetch(urlxz9, {
          method: "POST",
          headers: headersxz9,
          body: JSON.stringify(dataxrz9),
          // body: dataxr,
          // mode: 'no-cors'
        })
        .then((response: { json: () => any; }) => response.json())
        // Then with the data from the response in JSON...
    
        .then((datax9: any) => {
          if (datax9['status_code'] === 100) {
            if (datax9['response']['host_status'] === "4" && datax9['response']['login_status'] === "0") {
              // We are destroying all sessions at login ...
              // init a session for this user ///
              console.log("Session exiting and destroyed; " + datax9['response']['login_status']);
              // dispatch here ...
              // set session params here ...
              window.localStorage.removeItem('session_id');
              window.localStorage.removeItem('passcode');
              window.localStorage.removeItem('host_status');
              window.localStorage.removeItem('expiry_date');
              window.localStorage.removeItem('userx');
              // dispatch here    
              setSession(null);
              dispatch({ type: 'LOGOUT' });
            } else {
              console.log("Error happened in destroying a session ...");
              // handleCloseProgress();
              handleOpenError();
            }
          }
        })
        // Then with the error genereted...
        .catch((error: any) => {
          console.error('Error:', error);
          // return 'FAILEDx';
        });
    }
    // dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: CTIUser }>(
      '/api/authentication/register',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <>
    <Modal
      open={openProgress}
      onClose={handleCloseProgress}
    >
      {progressBody}
    </Modal>
    <Modal
      open={openError}
      onClose={handleCloseError}
    >
      {errorBody}
    </Modal>
    <Modal
      open={openErrorDrsAccess}
      onClose={handleCloseErrorDrsAccess}
    >
      {errorBodyDrsAccess}
    </Modal>
    <Modal
      open={openErrorClinicAccess}
      onClose={handleCloseErrorClinicAcess}
    >
      {errorBodyClinicAccess}
    </Modal>
    <Modal
      open={openErrord1}
      onClose={handleCloseErrord1}
    >
      {errorBodyd1}
    </Modal>
    <Modal
      open={openSuccess}
      onClose={handleCloseSuccess}
    >
      {successBody}
    </Modal>
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        ctilogin,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
    </>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
