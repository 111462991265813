/* eslint-disable prefer-template */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { FC, useState, useEffect } from 'react';
// import { useEffect } from 'react';
// import Modal from '@material-ui/core/Modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { alpha } from '@material-ui/core/styles';
import {
  Modal,
  TextField,
  Paper,
  Avatar,
  Button,
  Box,
  FormHelperText,
  Alert,
  InputAdornment,
  IconButton,
  Container,
  Typography
} from '@material-ui/core';
// import { alpha } from '@material-ui/core/styles';
import { string } from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import SaveIcon from '../../../icons/Save';
import CheckIcon from '../../../icons/Check';
import X from '../../../icons/X';
import EyeIcon from '../../../icons/Eye';
import EyeOffIcon from '../../../icons/EyeOff';

interface LoginAccFormProps {
  dialog?: String;
}

const LoginFirebaseAccessAble: FC<LoginAccFormProps> = (props) => {
  // let { dialog } = props;
  const isMountedRef = useIsMountedRef();
  const { ctilogin } = useAuth() as any;
  const { signInWithEmailAndPassword } = useAuth() as any;
  // const [openSuccess, setOpenSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // const [openSuccess, setOpenSuccess] = useState(false);
  // const navigate = useNavigate();
  // const { logout } = useAuth();
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
    // navigate('/dashboard/courses');
    // await logout();
  };
  const [openProgress, setOpenProgress] = useState(false);

  const handleOpenProgress = () => {
    setOpenProgress(true);
  };

  const handleCloseProgress = () => {
    setOpenProgress(false);
  };

  const [openError, setOpenError] = useState(false);

  // const [openProgress, setOpenProgress] = useState(false);

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const progressBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {/* <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar> */}
          <CircularProgress />
          <br />
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Accessing Clinic
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Please wait ...
            <br />
            {/* Thanks */}
          </Typography>
          {/* <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseError}
          >
            Okay
          </Button> */}
        </Paper>
      </Container>
    </Box>
  );

  const successBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              mb: 2
            }}
          >
            <CheckIcon />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Unit Content Added
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            A unit content has successfuly been added;
          </Typography>
          <Button
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseSuccess}
          >
            Okay, Noted
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  const errorBody = (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
              color: 'error.main',
              mb: 2
            }}
          >
            <X />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Error Detected
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Wrong Email, Username, Phonenumber  or Password ...
          </Typography>
          <Button
            color="secondary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            // to='/dashboard/courses/'
            onClick={handleCloseError}
          >
            Okay
          </Button>
        </Paper>
      </Container>
    </Box>
  );

  useEffect(() => {
    // gtm.push({ event: 'page_view' });
    console.log('effect fn');
  }, []);

  return (
    <div {...props}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            .shape({
              email: Yup
                .string()
                // .email('Must be a valid email')
                .max(255)
                .required('Email / Phonenumber / Username is required'),
              password: Yup
                .string()
                .max(255)
                .required('Password is required')
            })
        }
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            // console.error('Trying....');
            // console.log(dialog);
            // console.error('Trying....Sam1');
            // handleOpenSuccess();
            // handleOpenProgress();
            await ctilogin(values.email, values.password);
            // await signInWithEmailAndPassword(values.email, values.password);
            // console.error('Trying....Sam2');
            // console.log(dialog);
            // handleCloseProgress();
            // if (dialog === 'true') {
            //   handleOpenSuccess();
            // }
            // console.error('Am in ...');
            if (isMountedRef.current) {
              setStatus({ success: true });
              // setErrors({ submit: err.message });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            {/* <Modal
              open={openSuccess}
              onClose={handleCloseSuccess}
            >
              {successBody}
            </Modal> */}
            <Modal
              open={openProgress}
              onClose={handleCloseProgress}
            >
              {/* {errorBody} */}
              {progressBody}
            </Modal>
            <Modal
              open={openError}
              onClose={handleCloseError}
            >
              {errorBody}
              {/* {progressBody} */}
            </Modal>
            <Modal
              open={openSuccess}
              onClose={handleCloseSuccess}
            >
              {successBody}
            </Modal> 
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address / Phonenumber / Username"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              // type="password"
              value={values.password}
              variant="outlined"
              // variant="outlined"
              type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
              // onChange={someChangeHandler}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              {/* <Button
                color="primary"
                onClick={handleOpenSuccess}
                fullWidth
                size="large"
                // type="submit"
                variant="contained"
              >
                Log Inx
              </Button> */}
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Alert severity="info">
                {/* <div>
                  Contact Admin on
                  {' '}
                  <b>0789090933</b>
                  {' '}
                  for Account
                  {' '}
                  <b>Creation</b>
                </div> */}
                <div>
                  Health Care
                  {' '}
                  <b>for</b>
                  {' '}
                  the
                  {' '}
                  <b>Future</b>
                </div>
              </Alert>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

LoginFirebaseAccessAble.propTypes = {
  // @ts-ignore
  // customer: PropTypes.object.isRequired
  // applicant: PropTypes.object.isRequired,
  dialog: string,
};

export default LoginFirebaseAccessAble;
