/* eslint-disable prefer-template */
/* eslint-disable  @typescript-eslint/quotes */
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import './__mocks__';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import { BrowserRouter } from "./@types/react-router-dom";
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import ZoomVideo from '@zoom/videosdk';
import App from './App';
// import { AuthProvider } from './contexts/JWTContext';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import ZoomContext from './zoomcontext/zoom-context';
import * as serviceWorker from './serviceWorker';
import store from './store';
// import { BrowserRouter } from 'react-router-dom';

const zmClient = ZoomVideo.createClient();
// feb2023
// zmClient.init('en-US', `${window.location.origin}/lib`, 'zoom.us');

console.log('MULTIPLE LOGxdDAD' + zmClient.getMediaStream().isSupportMultipleVideos());

// await zmClient.init("en-US", `${window.location.origin}/lib`, 'zoom.us');
// const zmClient = ZoomVideo.createClient();
// const streamy = zmClient.getMediaStream();
// setMediaStream(streamy);
// console.log('MULTIPLE LOGx123ed1xy' + streamy.isSupportMultipleVideos());
// const stream = zmClient.getMediaStream();
// setMediaStream(stream);
// setIsSupportGalleryView(stream.isS

// const updateHandler = (registration: ServiceWorkerRegistration) => {
//   if (window.confirm("Update available. Do you want to reload?")) {
//     window.location.reload();
//   }
// };

// serviceWorker.register({
//   onUpdate: updateHandler,
// });

// console.log('MULTIPLE LOGxdd' + ZoomVideo.createClient().getMediaStream().isSupportMultipleVideos());

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <ZoomContext.Provider value={zmClient}>
                    <App />
                  </ZoomContext.Provider>  
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     {/* <ZoomContext.Provider value={zmClient}> */}
//     <App />
//     {/* </ZoomContext.Provider> */}
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

// If you want to enable client cache, register instead.
// serviceWorker.unregister();
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
