export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// const REACT_APP_FIREBASE_API_KEY="AIzaSyCfXLGt2745GsmpoWPpUCEi76R_ESLeL9c"
// const REACT_APP_FIREBASE_APP_ID="1:98012200606:web:0036434303cb53bf0ac58f"
// const REACT_APP_FIREBASE_AUTH_DOMAIN="accessable-89c46.firebaseapp.com"
// const REACT_APP_FIREBASE_DATABASE_URL="https://accessable-89c46.firebaseio.com"
// const REACT_APP_FIREBASE_MESSAGING_SENDER_ID="98012200606"
// const REACT_APP_FIREBASE_PROJECT_ID="accessable-89c46"
// const REACT_APP_FIREBASE_STORAGE_BUCKET="accessable-89c46.appspot.com"
// const REACT_APP_MEASUREMENT_ID="G-12YMQQLZ0D"

// export const firebaseConfig = {
//   apiKey: REACT_APP_FIREBASE_API_KEY,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// };

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};
